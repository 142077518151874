import React from 'react';
import alidados from '../../assets/images/home/section_12/logos-aliados.png';
import ContactForm from '../../components/generals/forms/contact';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__13() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__13">
                <div className="section__block">
                    <div className="texture"></div>
                    <div className="contentForm">
                        <div className="contentForm__content">
                            <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                                <h2>Obtén más información</h2>
                            </Animated>

                            <Animated animation={'animate__fadeInDown animate__delay-2s'}>
                                <h1>y se Socio Accionista</h1>
                            </Animated>
                            <Animated animation={'animate__fadeIn animate__delay-3s'}>
                                <ContactForm />
                            </Animated>
                        </div>
                    </div>
                </div>
                <div className="section__block">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3814.1401891317005!2d-96.72581442325601!3d17.065794083767724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce844bd6a2df83%3A0x1aede71d26266078!2sTemplo%20de%20Santo%20Domingo%20de%20Guzm%C3%A1n!5e0!3m2!1ses-419!2smx!4v1729012714896!5m2!1ses-419!2smx"
                        width="600"
                        height="450"
                        loading="lazy"
                    ></iframe>
                </div>
            </main>
        </>
    );
}
