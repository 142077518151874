import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type postState = {
    postData: any;
    postCount: number;
    categoryData: number;
    multimediaModal: boolean;
    multimediaID: string;
    multimediaType: string;

};

const initialState = {
    postData: null,
    categoryData: 0,
    postCount: 12,
    multimediaModal: false,
    multimediaID: "",
    multimediaType: "",
} as postState;

export const postSlice = createSlice({
    name: "postSlice",
    initialState,
    reducers: {
        setPostData: (state, action: PayloadAction<any>) => {
            state.postData = action.payload
        },
        setPostCategoryData: (state, action: PayloadAction<any>) => {
            state.categoryData = action.payload
        },
        setPostCount: (state, action: PayloadAction<any>) => {
            state.postCount = action.payload
        },
        setMultimediaModal: (state, action: PayloadAction<boolean>) => {
            state.multimediaModal = action.payload
        },
        setMultimediaID: (state, action: PayloadAction<string>) => {
            state.multimediaID = action.payload
        },
        setMultimediaType: (state, action: PayloadAction<string>) => {
            state.multimediaType = action.payload
        }
    },
});

export const {
    setPostData,
    setPostCategoryData,
    setPostCount,
    setMultimediaModal,
    setMultimediaID,
    setMultimediaType
} = postSlice.actions;
export default postSlice.reducer;
