import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainHeader from 'components/ui/header';
import HomePage from 'pages/home';
import ContactModal from 'components/generals/modal/contact';
import MenuMovil from 'components/ui/menu-movil';
import MainFooter from 'components/ui/footer';
import { useLocation } from 'react-router-dom';

import 'animate.css';
import './i18n';
function App() {
    const { pathname } = useLocation();
    console.log(pathname);
    return (
        <>
            <MainHeader />
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
            <MenuMovil />
            <ContactModal />
            <MainFooter />
        </>
    );
}

export default App;
