import React from 'react';
import edificio from '../../assets/images/home/section_2/foto-edificio.png';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__2() {
    return (
        <>
            <main className="nv__section nv__section--no-margin   nv__hp__section__2">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                        <h1>En The Spots Of The World</h1>
                    </Animated>

                    <Animated animation={'animate__fadeInDown animate__delay-2s'}>
                        <p>
                            Te ofrecemos una oportunidad única para ser parte de un negocio
                            emocionante y altamente rentable.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInDown animate__delay-3s'}>
                        <h2>
                            Imagina ser el dueño de los lugares más emblemáticos de los destinos
                            turísticos más codiciados del mundo.
                        </h2>
                    </Animated>

                    <Animated animation={'animate__fadeInDown animate__delay-4s'}>
                        <p>
                            Nuestra plataforma te brinda la posibilidad de adquirir y ser parte de
                            proyectos arquitectónicos emblemáticos que no solo generan ganancias,
                            sino que también promueven la cultura, la tradición y el arte.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInDown  animate__delay-5s'}>
                        <ActionButton text="Inicia Ahora" type="rounded--big" color="green" />
                    </Animated>
                </section>

                <div className="photo__edificio">
                    <Animated animation={'animate__fadeInUp  animate__delay-2s'}>
                        <img src={edificio} alt="Edificio" />
                    </Animated>
                </div>
            </main>
        </>
    );
}
