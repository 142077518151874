import React from 'react';
import narvalLogo from 'assets/images/logos/logo-white-blue.png';
import { NVInput } from 'components/generals/inputs/nv__input';
import { NVTextArea } from 'components/generals/inputs/nv__textArea';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from 'schemas/contact.form.schema';
import { ContactFormInterface } from 'interfaces/contact.form.interface';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ContactInfo from 'components/generals/forms/contact/contactInfo';
import config from 'config';
export default function ContactForm() {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ContactFormInterface>({
        resolver: yupResolver(contactFormSchema),
        shouldFocusError: false,
    });

    const onSubmit: SubmitHandler<ContactFormInterface> = async (data) => {
        console.log(data);

        const message = `Hola, soy ${data.name}, mi correo es ${data.email} y mi teléfono es ${data.phone}. ${data.message}`;

        const url = `https://wa.me/${'5625083441'}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
        reset();
        //message.success('Mensaje enviado correctamente', 3);
    };

    return (
        <main className="nv__contact__form">
            <div className="contact__form__base__container">
                <div className="contact__form__fields">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <span className="form__title">Nombre</span>
                        <NVInput
                            name="name"
                            type="text"
                            placeholder={''}
                            control={control}
                            className=""
                            errorMessage={errors.name?.message}
                        />
                        <section className="form__group">
                            <div>
                                <span className="form__title">Correo</span>
                                <NVInput
                                    name="email"
                                    type="text"
                                    placeholder={''}
                                    control={control}
                                    className=""
                                    errorMessage={errors.email?.message}
                                />{' '}
                            </div>

                            <div>
                                <span className="form__title">Teléfono</span>
                                <NVInput
                                    name="phone"
                                    type="text"
                                    placeholder={''}
                                    control={control}
                                    className=""
                                    errorMessage={errors.phone?.message}
                                />
                            </div>
                        </section>
                        <span className="form__title">Mensaje</span>

                        <NVTextArea
                            name="message"
                            control={control}
                            className=""
                            errorMessage={errors.message?.message}
                        />

                        <input type="submit" value={'Enviar'} />
                    </form>
                </div>
            </div>
        </main>
    );
}
