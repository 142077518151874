import React from 'react';
import { Controller, Control } from 'react-hook-form';

interface NVInputProps {
    control: Control<any>;
    name: string;
    type: string;
    className: string;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    errorMessage: string | undefined;
}

export const NVInput = (props: NVInputProps) => {
    return (
        <Controller
            {...props}
            render={({ field }) => {
                return (
                    <>
                        <input
                            disabled={props.disabled}
                            className={props.className}
                            type={props.type}
                            {...field}
                            placeholder={props.placeholder}
                            value={field.value || ''}
                        />
                        <span className="labelError mt-5">{props.errorMessage}</span>
                    </>
                );
            }}
        />
    );
};
