import React from 'react';
import { Controller, Control } from 'react-hook-form';

interface SGTextAreaProps {
    control: Control<any>;
    name: string;
    className: string;
    placeholder?: string;
    value?: string;
    errorMessage: string | undefined;
}

export const NVTextArea = (props: SGTextAreaProps) => {
    return (
        <Controller
            {...props}
            render={({ field }) => {
                return (
                    <>
                        <textarea
                            {...field}
                            placeholder={props.placeholder}
                            value={field.value || ''}
                        />
                        <span className="labelError mt-5">{props.errorMessage}</span>
                    </>
                );
            }}
        />
    );
};
