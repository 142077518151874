import React from 'react';
import { useInView } from 'react-intersection-observer';

export default function Animated({ children, animation }: any) {
    const { ref, inView } = useInView({
        triggerOnce: true, // La animación se dispara solo una vez
        threshold: 0.1,
    });

    return (
        <div ref={ref} className={`animate__animated animated-item ${inView ? animation : ''}`}>
            {children}
        </div>
    );
}
