import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base } from "constants/API"
import { fields, FIELDS_POST } from "constants/fields"
import { PostObject, loopParams } from 'interfaces/post'
import { SingleObject } from 'interfaces/single'

const PATH = "posts";

export const postApi = createApi({
    reducerPath: "postApi",
    refetchOnFocus: false,

    baseQuery: fetchBaseQuery({
        baseUrl: api_base,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: "include"
    }),

    tagTypes: ['posts'],

    endpoints: (builder) => ({
        getPost: builder.query<PostObject, loopParams>({
            query: (params) => `wp/v2/${PATH}/?_fields=${fields}&per_page=${params.per_page}&page=${params.page}&sticky=${params.sticky}&offset=${params.offset}`,
            providesTags: ["posts"]
        }),

        getPostByCategoryID: builder.query<PostObject, loopParams>({
            query: (params) => `wp/v2/${PATH}/?_fields=${fields}&per_page=${params.per_page}&page=${params.page}&categories=${params.category_id}`,
            providesTags: ["posts"]
        }),
        getPostByCategoryName: builder.query<PostObject, loopParams>({
            query: (params) => `wp/v2/${PATH}/?_fields=${fields}&per_page=${params.per_page}&page=${params.page}&categories=${params.category_id}`,
            providesTags: ["posts"]
        }),
        getPostBySlug: builder.query<SingleObject, loopParams>({
            query: (params) => `wp/v2/${PATH}/?_fields=${FIELDS_POST}&slug=${params.slug}`,
            providesTags: ["posts"]
        }),

    }),
});

export const {
    useGetPostQuery,
    useGetPostByCategoryIDQuery,
    useGetPostByCategoryNameQuery,
    useGetPostBySlugQuery
} = postApi;
