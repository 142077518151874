import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import alidados from '../../assets/images/home/section_12/logos-aliados.png';
import alidados_movil from '../../assets/images/home/section_12/logos-aliados-movil.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__12() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__12">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                        <h1>
                            Aliados <br /> Comerciales
                        </h1>
                    </Animated>

                    <div className="logos_aliados">
                        <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                            <img src={alidados} className="logos_aliados_web" />
                            <img src={alidados_movil} className="logos_aliados_movil" />
                        </Animated>
                    </div>
                </section>
            </main>
        </>
    );
}
