import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Home__section__1 from 'pages/home/section_1';
import Home__section__2 from 'pages/home/section_2';
import Home__section__3 from 'pages/home/section_3';
import Home__section__4 from 'pages/home/section_4';
import Home__section__5 from 'pages/home/section_5';
import Home__section__6 from 'pages/home/section_6';
import Home__section__7 from 'pages/home/section_7';
import Home__section__8 from 'pages/home/section_8';
import Home__section__9 from 'pages/home/section_9';
import Home__section__10 from 'pages/home/section_10';
import Home__section__11 from 'pages/home/section_11';
import Home__section__12 from 'pages/home/section_12';
import Home__section__13 from 'pages/home/section_13';

export default function HomePage() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <main className="main__page main__page--full nv__home__page">
            <Home__section__1 />
            <Home__section__2 />
            <Home__section__3 />
            <Home__section__4 />
            <Home__section__5 />
            <Home__section__6 />
            <Home__section__7 />
            <Home__section__8 />
            <Home__section__9 />
            <Home__section__10 />
            <Home__section__11 />
            <Home__section__12 />
            <Home__section__13 />
        </main>
    );
}
