import React from 'react';

import logo from '../../assets/images/home/section_5/logo_ssd.png';
import church from '../../assets/images/home/section_5/church.png';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__5() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__5">
                <section className="section__block section__block--church section__block--web">
                    <div className="church__asset">
                        <Animated animation={'animate__fadeInLeft'}>
                            <img src={church} alt="church" />
                        </Animated>
                    </div>
                    <div className="texture"></div>
                </section>

                <section className="section__block section__block--second">
                    <section className="section__info">
                        <Animated animation={'animate__fadeInDown  animate__delay-1s'}>
                            <h2>Frente al majestuoso templo de </h2>
                        </Animated>
                        <Animated animation={'animate__fadeInDown  animate__delay-2s'}>
                            <h1>
                                Santo Domingo <br /> de Guzmán{' '}
                            </h1>
                        </Animated>
                        <Animated animation={'animate__fadeInDown  animate__delay-3s'}>
                            <p>Catalogada como patrimonio Cultural de la humanidad.</p>
                        </Animated>

                        <Animated animation={'animate__fadeInDown  animate__delay-4s'}>
                            <ActionButton text="SE DUEÑO AHORA" type="rounded--big" color="cream" />
                        </Animated>
                    </section>

                    <div className="logo">
                        <Animated animation={'animate__fadeIn  animate__delay-5s'}>
                            <img src={logo} alt="Logo" />
                        </Animated>
                    </div>
                </section>
            </main>
        </>
    );
}
