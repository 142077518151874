import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type calculatorState = {
    data: any
};

const initialState = {
 

} as calculatorState;

export const generalSlice = createSlice({
    name: "generalSlice",
    initialState,
    reducers: {

        setData: (state, action: PayloadAction<number>) => {
            state.data = action.payload
        },
    },

});

export const {
    setData
} = generalSlice.actions;
export default generalSlice.reducer;
