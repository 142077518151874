import React, { useState } from 'react';
import { Drawer } from 'antd';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import headerLogo from 'assets/images/logos/ssd-logo-cream.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import ActionButton from 'components/ui/buttons/action-button';
import { Menu } from 'antd';

export default function MenuMovil() {
    const dispatch = useAppDispatch();
    const displayMobileMenu = useAppSelector((state) => state.contactSlice.displayMobileMenu);
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(false);
    type MenuItem = Required<MenuProps>['items'][number];

    const items: MenuItem[] = [
        {
            key: 'item1',
            label: <Link to="/#caracteristicas"> {'Características'}</Link>,
        },
        {
            key: 'item2',
            label: <Link to="/#precios"> {'Precios'}</Link>,
        },
        {
            key: 'item3',
            label: <Link to="/#comunidad"> {'Comunidad'}</Link>,
        },
    ];

    const onClose = () => {
        dispatch(setDisplayMobileMenu(false));
    };

    return (
        <Drawer title="" onClose={onClose} open={displayMobileMenu}>
            <div
                className="nv__header__logo nv__header__logo--mobile"
                onClick={() => {
                    onClose();
                }}
            >
                <Link to="/#home">
                    <img src={headerLogo} alt="Logo Narval white blue" />
                </Link>
            </div>

            <section>
                <Menu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                    items={items}
                    onClick={() => {
                        onClose();
                    }}
                />
            </section>

            <ActionButton
                text={'Ingresa ahora'}
                type="rounded--small"
                onClick={() => {
                    // dispatch(setDisplayModal(true));
                }}
            />
        </Drawer>
    );
}
