import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cuadro1 from 'assets/images/home/section_9/cuadro_1.png';
import cuadro2 from 'assets/images/home/section_9/cuadro_2.png';
import cuadro3 from 'assets/images/home/section_9/cuadro_3.png';
import cuadro4 from 'assets/images/home/section_9/cuadro_4.png';
import cuadro5 from 'assets/images/home/section_9/cuadro_5.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__9() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [location]);

    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__9" id="precios">
                <section className="nv__section__container">
                    <div className="title-with-lines">
                        <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                            <h1>Tickets</h1>
                        </Animated>
                    </div>
                    <Animated animation={'animate__fadeInUp animate__delay-2s'}>
                        <h2>50% de enganche</h2>
                    </Animated>

                    <div className="squares">
                        <div className="squares__items">
                            <Animated animation={'animate__fadeInUp animate__delay-2s'}>
                                <img src={cuadro1} alt="Número 1" />
                            </Animated>
                        </div>
                        <div className="squares__items">
                            <Animated animation={'animate__fadeInDown animate__delay-3s'}>
                                <img src={cuadro2} alt="Número 2" />
                            </Animated>
                        </div>
                        <div className="squares__items">
                            <Animated animation={'animate__fadeInUp animate__delay-4s'}>
                                <img src={cuadro3} alt="Número 3" />
                            </Animated>
                        </div>
                        <div className="squares__items">
                            <Animated animation={'animate__fadeInDown animate__delay-5s'}>
                                <img src={cuadro4} alt="Número 4" />
                            </Animated>
                        </div>

                        <div className="squares__items">
                            <Animated animation={'animate__fadeInUp animate__delay-6s'}>
                                <img src={cuadro5} alt="Número 5" />
                            </Animated>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
