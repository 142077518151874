import React from 'react';
import number from '../../assets/images/home/section_10/number.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__10() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__10">
                <div className="overlay"></div>
                <section className="nv__section__container">
                    <div className="number">
                        <Animated animation={'animate__fadeInUp'}>
                            <img src={number} alt="Unidad de negocio" />
                        </Animated>
                    </div>
                </section>

                <div className="texture"></div>
            </main>
        </>
    );
}
