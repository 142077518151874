import React, { useState, useEffect } from 'react';
import headerLogo from 'assets/images/logos/ssd-logo-cream.png';
import Menu from 'components/ui/menu';
import ActionButton from 'components/ui/buttons/action-button';
import { useTranslation } from 'react-i18next';
import { IoMdMenu } from 'react-icons/io';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal } from 'reduxStore/slices/contact.slice';
import { Link } from 'react-router-dom';
import Animated from 'components/generals/AnimatedComponent';
import { setDisplayMobileMenu } from 'reduxStore/slices/contact.slice';
import LanguangeSelector from 'components/generals/language-selector';

export default function MainHeader() {
    const dispatch = useAppDispatch();
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuClasses = [''];
    if (scrolled) {
        menuClasses.push('scrolled transition');
    }

    return (
        <>
            <header className={`nv__header ${menuClasses.join(' ')}`}>
                <section className="nv__header__container">
                    <div className="nv__header__logo">
                        <Animated animation={'animate__fadeInDown logo-absolute'}>
                            <Link to="/#home">
                                <img src={headerLogo} alt="Logo SSD" />
                            </Link>
                        </Animated>
                    </div>

                    <Animated animation={'animate__fadeInDown header-menu'}>
                        <Menu />
                    </Animated>
                    <Animated animation={'animate__fadeInDown'}>
                        <section className="nv__header__button__section">
                            <div
                                className="nv__header__menu nv__header__menu--mobile"
                                onClick={() => {
                                    dispatch(setDisplayMobileMenu(true));
                                }}
                            >
                                <IoMdMenu />
                            </div>

                            <ActionButton
                                text={'Ingresa ahora'}
                                type="rounded"
                                onClick={() => {
                                    // dispatch(setDisplayModal(true));
                                }}
                            />
                        </section>
                    </Animated>
                </section>
            </header>
        </>
    );
}
