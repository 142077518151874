import React from 'react';

import logo from 'assets/images/logos/ssd-logo-cream.png';
import Animated from 'components/generals/AnimatedComponent';

export default function Home__section__8() {
    return (
        <>
            <main className="nv__section nv__section--no-margin  nv__hp__section__8">
                <section className="nv__section__container">
                    <Animated animation={'animate__fadeInDown animate__delay-1s'}>
                        <h1>Bienvenido a</h1>
                    </Animated>

                    <div className="section__logo">
                        <Animated animation={'animate__fadeInUp animate__delay-2s'}>
                            <img src={logo} alt="SSD Logo" />
                        </Animated>
                    </div>
                </section>
            </main>
        </>
    );
}
